export const getLinkItem = (item: object) => {
  if (!item) { return }
  if (item instanceof Object === false) { return }
  const data = baseStrapiGetAttributes(item)

  let firstFilledType = null
  for (const [key, value] of Object.entries(data)) {
    if (value instanceof Object && value?.data?.attributes) {
      firstFilledType = {
        uid: key,
        ...value?.data?.attributes
      }
      break
    }
  }

  const firstFilledTypeEn = firstFilledType?.localizations?.data[0]?.attributes
  const locales = [firstFilledType, firstFilledTypeEn]?.filter(Boolean)
  const linkItemLocalized = locales?.find(
    ({ locale }) => locale === baseI18nGetActiveIso()
  )

  return { uid: firstFilledType?.uid, ...linkItemLocalized }
}
