<template>
  <menu
    :class="$style.root"
    ref="element"
    tabindex="0"
    @keyup.esc="$emit('onCloseMenu')"
  >
    <div
      :class="$style.content"
      ref="content"
    >
      <!-- @mouseleave="handleMouseleave"
      @mouseenter="handleMouseenter" -->
      <NavigationMenuHeadingComponent
        @onCloseMenu="$emit('onCloseMenu')"
        :class="$style.heading"
      />

      <ul
        :class="[$style.section, $style.items]"
      >
        <li
          v-for="(item, index) in page?.items?.data"
          :key="item?.id"
          :class="$style.item"
        >
          <NavigationLinkComponent
            :item="item"
            :class="$style.link"
          />

          <ul
            v-if="item?.attributes?.children?.data?.length"
            :class="$style.childItems"
          >
            <li
              v-for="(childItem, index) in item?.attributes?.children?.data"
              :key="item?.id"
            >
              <NavigationLinkComponent
                :item="childItem"
              />
            </li>
          </ul>
        </li>
      </ul>

      <ul :class="[$style.section, $style.bottom]">
        <li>
          <i18nLink :to="{name: 'newsletter'}" class="reset-link link-underline">{{ $t('newsletter.label') }}</i18nLink>
        </li>
        <li>
          <button
            @click="[$emit('onOpenSearch'), $emit('onCloseMenu')]"
            :class="$style.button"
          >
            {{ $t('search.button.label') }}
          </button>
        </li>
        <li>
          <NavigationLanguageSwitcherComponent />
        </li>
      </ul>
    </div>
  </menu>
</template>

<script setup>
  // 1 setup
  const props = defineProps({
    isOpen: {
      type: Boolean,
      default: false
    }
  })

  const element = ref(null)
  const nuxtApp = useNuxtApp()
  const content = ref(null)
  const emit = defineEmits(['onCloseMenu', 'onOpenSearch'])
  const router = useRouter()
  const timeout = ref(null)
  const menuIsOpen = computed(() => props.isOpen)

  // 2 fetch data
  const i18nActiveIso = computed(() => baseI18nGetActiveIso())

  const { data, error } = await useAsyncData(() =>
    fetchQueryPath( queryMenusMenu(i18nActiveIso.value) ), { watch: [i18nActiveIso] }
  )

  const page = computed(() => baseStrapiGetAttributes(data))

  // 3 open & closing
  nuxtApp.hook('page:start', () => { emit('onCloseMenu') })

  onClickOutside(content, (event) => {
    emit('onCloseMenu')
  }, { ignore: ['button', 'a']} )

  watch(() => props.isOpen, (isOpen) => {
    clearTimeout(timeout.value)
  })

  // 4 prevent scroll
  watch(menuIsOpen, () => {
    if (props?.isOpen) {
      document.documentElement.setAttribute('data-overflow-hidden', '')
      nextTick(() => {
        element.value.focus()
      })
    } else {
      document.documentElement.removeAttribute('data-overflow-hidden')
    }
  })
</script>

<style module lang="scss">
.root {
  composes: font-size-small font-helvetica from global;
  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index--menu);

  overflow: scroll;
  max-height: var(--100dvh);

  @media (max-width: $breakpoint-s) {
    bottom: 0;
  }

  --color--background: var(--color--white);
  --color--primary: var(--color--black);
}

.root:before {
  content: '';

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: calc(var(--z-index--menu) - 1);
}

.root[class*="mask"] {
  pointer-events: none;
}

html[data-page-type="homepage"] .root,
html[data-page-type="slug"] .root,
html[data-page-type="newsletter"] .root {
  --color--background: var(--color--black);
  --color--primary: var(--color--white);
}

.root:focus {
  outline: none;
}

.content {
  padding: var(--unit--vertical) var(--unit--default);
  position: relative;
  z-index: calc(var(--z-index--menu) + 1);

  background-color: var(--color--background);
  color: var(--color--primary);

  --spacing: calc((var(--font-small--line-height) / 2) - (var(--stroke--default) / 2));

  @media (max-width: $breakpoint-s) {
    height: 100%;
  }
}

.heading {
  border-bottom: var(--stroke--default) solid;
  padding-bottom: var(--unit--default);
}

.section {
  composes: reset-list from global;
}

.section:not(:last-of-type) {
}

.items {
  columns: 2;
  gap: var(--unit--vertical);

  overflow: hidden;

  padding-bottom: var(--unit--default);

  @media (max-width: $breakpoint-s) {
    columns: 1;
  }
}

.item {
  border-bottom: var(--stroke--default) solid;
  padding-bottom: var(--spacing);
  padding-top: var(--spacing);
  break-inside: avoid;
}

.childItems {
  composes: reset-list from global;

  display: flex;
  flex-direction: column;
}

.button {
  composes: reset-button from global;
}

.button[disabled] {
  pointer-events: none;
}

.button:hover {
  text-decoration-line: underline;
}
</style>
