import qs from 'qs'

export const queryFooter = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      fields: [
        'address',
        'openingTimes'
      ],
      populate: {
        ...queryFragmentBlocksLinks,
        sponsors: {
          populate: {
            logo: {
              populate: '*'
            }
          }
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer?${query}`
}
