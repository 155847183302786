<template>
  <div
    :class="$style.root"
    ref="element"
    @keyup.esc="$emit('closeSelf')"
    tabindex="0"
  >
    <div :class="$style.content" ref="content">
      <div :class="$style.top">
        <input
          v-model="queryInput"
          :class="$style.input"
          :placeholder="$t('search.placeholder')"
          ref="inputElement"
          tabindex="0"
          :disabled="isPending ? '' : null"
        >

        <button
          :class="$style.button"
          @click="$emit('closeSelf')"
        >
          ×
        </button>
      </div>

      <SearchHitsComponent
        :queryString="query"
        @pending="isPending = $event"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'
const nuxtApp = useNuxtApp()
const route = useRoute()
const emit = defineEmits(['closeSelf'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const content = ref(null)
const inputElement = ref(null)
const isPending = ref(false)
const path = computed(() => route?.path)
const queryInput = ref(null)

const query = refDebounced(queryInput, 750)

onClickOutside(content, (event) => emit('closeSelf'), {ignore: ['button']})

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      inputElement.value?.focus()
    }, 50)
  } else {
    queryInput.value = null
    query.value = null
  }
})

watch(path, () => {
  emit('closeSelf')
  queryInput.value = null
  query.value = null
})
</script>

<style module lang="scss">
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index--search);

  max-height: calc(var(--100svh) - (var(--unit--vertical) * 2));
  max-width: 100vw;
  overflow: scroll;

  background-color: var(--color--primary);
  color: var(--color--background);
  padding: var(--unit--vertical) var(--unit--default) var(--unit--vertical) var(--unit--default);

  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-s) {
    right: 0;
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1ch;

  margin-bottom: calc(var(--stroke--default) * -2);
}

.button {
  composes: reset-button font-helvetica font-size-small from global;

  transform: scale(1.15);
}

.input {
  composes: reset-input font-helvetica font-size-small from global;

  padding-bottom: var(--stroke--default);
  border-bottom: var(--stroke--default) solid;

  min-width: 20rem;
  flex: 1;
}

.input::placeholder {
  color: var(--color--background);
}

.linkToParent:empty {
  display: none;
}
</style>
