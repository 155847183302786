<template>
  <div :class="$style.root">
    <ul v-if="resultsSorted?.length" :class="$style.results">
      <li
        v-for="(searchResult, index) in resultsSorted"
        :key="searchResult?.indexUid"
        :class="$style.hitGroup"
        :data-single-type="searchResult?.indexUid?.endsWith('-page') ? '' : null"
        :data-last-of-single-type="!resultsSorted[index + 1]?.indexUid?.endsWith('-page') && searchResult?.indexUid?.endsWith('-page') ? '' : null"
      >
        <SearchHitsGroupLabelComponent
          :indexUid="searchResult?.indexUid"
        />
        <ul :class="$style.group">
          <li v-for="(hit, index) in searchResult?.hits" :key="hit?._meilisearch_id">
            <SearchHitsHitComponent :hit="hit" :uid="searchResult?.indexUid" />
          </li>
        </ul>
      </li>
    </ul>

    <div v-else-if="query && !resultsSorted?.length && !pending" :class="$style.notification"
      v-html="$t('search.noResults', { query: query })" />

    <div v-else-if="query && pending" :class="$style.notification" v-html="'Loading …'" />
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['pending'])
import { orderBy } from 'lodash-es'

const props = defineProps({
  queryString: {
    type: String,
    default: false
  }
})

const query = computed(() => props.queryString?.length ? props.queryString : undefined)

const dataIfQuery = ref(null)
const results = computed(() => dataIfQuery?.value?.results)
const resultsSorted = computed(() => {
  const resultsWithResults = results?.value?.filter((result: object) => result.estimatedTotalHits !== 0)
  return orderBy(resultsWithResults, [(i) => i?.indexUid?.endsWith('-page') || i?.indexUid === 'page-entry', (i) => getSearchLabel(i?.indexUid), (i) => i?.hits[0]?.title], ['desc', 'asc', 'asc'])
})

const { data, pending } = await useAsyncData(() => {
  return $fetch('/api/meilisearch', { query: { query: query?.value, locale: baseI18nGetActiveIso() } })
}, { watch: [query] })

watch(data, () => {
  query?.value ? dataIfQuery.value = data?.value : dataIfQuery.value = null
}, { immediate: true })
</script>

<style module>
.root:not(:empty) {
  margin-top: var(--unit--vertical);
}

.results {
  composes: reset-list font-size-small font-helvetica from global;
}

.notification {
  composes: font-size-small font-helvetica from global;
  padding-bottom: calc(var(--unit--vertical) + var(--padding--button--large--top));
  color: var(--color--secondary);
}

.notification mark {
  background-color: transparent;
  color: var(--color--background);
}

.group {
  composes: reset-list from global;
}

.hitGroup[data-last-of-single-type],
.hitGroup:not([data-single-type]):not(:last-of-type) {
  padding-bottom: var(--unit--vertical);
}

.hitGroup:last-of-type {
  padding-bottom: 0;
}
</style>
