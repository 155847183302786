<template>
  <div
    :class="$style.root"
    id="site-header"
  >
    <div :class="$style.catchphrase">
      <i18n-link
        :to="{ name: 'index' }"
        :class="$style.link"
      >
        {{ $t('site.header.catchphrase') }}
      </i18n-link>
    </div>

    <button
      :class="$style.button"
      @click="onClickOpenMenu"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
    >
      {{ $t('site.header.menu') }}
    </button>

    <button
      :class="$style.search"
      @click="$emit('onToggleSearch')"
    >
      {{ $t('search.button.label') }}
    </button>

    <div :class="$style.links" v-if="items">
      <NavigationSiteHeaderItemComponent
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="$style.link"
      />
    </div>


    <NavigationLanguageSwitcherComponent :class="$style.languageSwitcher" />

    <div :class="$style.logo">
      <SharedAnimatedSvgLogoSiteHeaderComponent
        :showLogo="!hideLogoDebounced"
      />
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['onToggleSearch', 'onOpenMenu'])
import { refDebounced } from '@vueuse/core'

// 1
const i18nActiveIso = computed(() => baseI18nGetActiveIso())

const { data, error } = await useAsyncData(() =>
  fetchQueryPath( queryMenusSiteNav(i18nActiveIso.value) ), { watch: [i18nActiveIso] }
)

const page = computed(() => baseStrapiGetAttributes(data))
const items = computed(() => page.value?.items?.data)

// 2
const timeout = ref(null)
const handleMouseenter = () => {
  timeout.value = setTimeout(() => {
    emit('onOpenMenu')
  }, 150)
}

const handleMouseleave = () => {
  clearTimeout(timeout.value)
}

const onClickOpenMenu = () => {
  clearTimeout(timeout.value)
  emit('onOpenMenu')
}

// 3
const hideLogo = ref(false)
const hideLogoDebounced = refDebounced(hideLogo, 350)
const { y } = useWindowScroll()
const elementHeight = computed(() => {
  if (process.client === false) return 0
  const element = document.getElementById('site-header')
  return element?.offsetHeight
})
watch(y, (value) => {
  hideLogo.value = value > 0
}, { immediate: true})
</script>

<style module lang="scss">
.root {
  composes: font-helvetica font-size-small from global;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  background-color: var(--color--background);
  color: var(--color--primary);

  padding: var(--unit--vertical) var(--unit--default) var(--unit--vertical) var(--unit--default);

  top: 0;
  z-index: var(--z-index--site-header);
  position: sticky;
}

.links {
  overflow: hidden;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: $breakpoint-m) {
    display: none;
  }
}

.link {
  composes: reset-link from global;
  margin-right: 3ch;

}

.link:hover,
.link:global(.router-link-exact-active) {
  text-decoration-line: underline;
}

.catchphrase {
  white-space: nowrap;
}

.catchphrase {
  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.button {
  composes: reset-button from global;
  margin-right: 3ch;
}

.button:hover {
  text-decoration-line: underline;
}

.search {
  composes: button;
}

.languageSwitcher,
.search {
  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.logo {
  overflow: visible;
  height: var(--font-small--line-height);
  display: flex;
  align-items: center;

  margin-left: auto;
}
</style>
