<template>
  <div
    :class="$style.root"
    :data-visible="visible ? '' : null"
  >
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 549.04 96.4"
      :class="$style.svg"
    >
      <line
        :class="$style.line"
        vector-effect="non-scaling-stroke"
        :style="`--animation--step: ${1}`"
        x1="549.01" y1="38.35" x2="232.81" y2=".25"
        @animationend="onAnimationend"
        />
        <line
        :class="$style.line"
        vector-effect="non-scaling-stroke"
        :style="`--animation--step: ${2}`"
        x2="90" y2="32" x1="539.91" y1="52.75"
        @animationend="onAnimationend"
      />
      <line
        :class="$style.line"
        vector-effect="non-scaling-stroke"
        :style="`--animation--step: ${3}`"
        x2=".01" y2="96.15" x1="525.61" y1="68.05"
        @animationend="onAnimationend"
      />
    </svg>

    <span :class="$style.logo" :style="`--animation--step: ${4}`">Nest</span>
  </div>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const mainStore = useMainStore()

const props = defineProps({
  showLogo: {
    type: Boolean,
    default: true
  }
})

// const onPageChange = ref(true)
// nuxtApp.hook('page:start', () => {
//   onPageChange.value = false
// })
// nuxtApp.hook('page:finish', () => {
//   onPageChange.value = true
// })

const visible = computed(() => {
  return props.showLogo //&& onPageChange.value && mainStore?.showLogo
})

watch(visible, (value) => {
  linesDrawn.value = 0
})

const linesDrawn = ref(0)

const onAnimationend = (e) => {
  linesDrawn.value++
}
</script>

<style module lang="scss">
.root {
  position: relative;
  user-select: none;

  --height: calc(var(--font-default--line-height) + var(--padding--element--top) * 3.5);
  // @media (max-width: $breakpoint-m) {
  //   --height: calc(var(--font-small--line-height) + var(--padding--element--top) * 3.15);
  // }

  overflow: visible;
  height: var(--height);

  max-width: calc(100% - 8ch - (var(--unit--default) * 4));
}

.root path {
  stroke: var(--color--primary);
}

.svg {
  height: 100%;
  overflow: visible;
}

.line, .text, .logo {
  --speed: calc(.095s + calc(var(--animation--step) * .025s));
  --delay: calc(var(--animation--step) * .1s);
}

.line {
  stroke: var(--color--primary);
  stroke-width: .1em;
}

.root[data-visible] .line {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;

  animation: draw var(--speed) var(--delay) linear forwards;
}

.root:not([data-visible]) .line {
  stroke: var(--color--primary);
  stroke-dasharray: 700;
  animation: hide calc(var(--speed) / 2) var(--delay) linear forwards;
}

@keyframes draw {
  from {
    stroke-dashoffset: 700;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes hide {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 700;
  }
}

.logo {
  composes: font-default font-size-default from global;
  position: absolute;
  left: 0;
  bottom: 0;

  transform: rotate(-2.5deg);

  line-height: 1.05;
}

.root[data-visible] .logo {
  opacity: 0;
  animation: showText 0s calc(var(--speed) + var(--delay) * .75) linear forwards;
}

.root:not([data-visible]) .logo {
  opacity: 0;
}

@keyframes showText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
