export const getSearchLabel = (indexUid: string) => {
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp

  switch (indexUid) {
    case 'education-entry':
      return $i18n.t('pages.education.label')
      break
    case 'event-entry':
      return $i18n.t('pages.events.label')
      break
    case 'exhibition-entry':
      return $i18n.t('pages.exhibitions.label')
      break
    case 'journal-entry':
      return $i18n.t('pages.journals.label')
      break
    case 'project-entry':
      return $i18n.t('pages.journals.label')
      break
  }
}
