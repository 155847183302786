<template>
  <footer
    v-if="attributes(data)"
    :class="$style.root"
  >
    <div :class="$style.content">
      <div :class="$style.leftColumn">
        <CoreTextComponent
          :key="attributes(data)?.address"
          v-if="attributes(data)?.address"
          :string="attributes(data)?.address"
        />

        <CoreTextComponent
          v-if="attributes(data)?.openingTimes"
          :key="attributes(data)?.openingTimes"
          :string="attributes(data)?.openingTimes"
        />
      </div>

      <div :class="$style.rightColumn">
        <ul :class="$style.links">
          <li
            v-for="link in attributes(data)?.links"
            :key="link?.id"
          >
            <SharedLinkComponent
              :link="link"
              :class="$style.link"
            >
                {{ link?.label }}
            </SharedLinkComponent>
          </li>
          <li>
            <i18nLink
              :to="{ name: 'newsletter' }"
              :class="$style.link"
            >
              {{ $t('newsletter.label') }}
            </i18nLink>
          </li>
        </ul>

        <SharedFooterSponsorLogosComponent />
      </div>
    </div>
  </footer>
</template>

<script setup>
const props = defineProps({
  logo: {
    type: Boolean,
    default: true
  }
})

const i18nActiveIso = computed(() => baseI18nGetActiveIso())

const { data, error } = await useAsyncData(() =>
  fetchQueryPath( queryFooter(i18nActiveIso.value) ), { watch: [i18nActiveIso] }
)

const attributes = baseStrapiGetAttributes

const instance = getCurrentInstance()
watch(data, () => {
  instance?.proxy?.$forceUpdate()
})
</script>

<style module lang="scss">
.root {
  composes: container font-size-default from global;

  margin-top: var(--shared--footer--margin-top, calc(var(--unit--vertical) * 4));
  margin-bottom: var(--unit--vertical);

  background-color: var(--footer--background-color);
  padding-top: var(--footer--padding-top, 0px);
  padding-bottom: var(--footer--padding-bottom, 0px);

  display: var(--shared--footer--display, initial);
}

html[data-pending] .root {
  display: none;
}

.content {
  gap: var(--unit--vertical);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-template-areas: 'leftColumn rightColumn';

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    grid-template-areas: 'leftColumn' 'rightColumn';
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--unit--vertical);
}

.logo {
  grid-area: logo;
}

.leftColumn {
  composes: column;
  grid-area: leftColumn;
}

.rightColumn {
  composes: column;
  grid-area: rightColumn;
}

.links {
  composes: reset-list from global;
}

.link {
  composes: reset-link link from global;
  display: inline;
}
</style>
