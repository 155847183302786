<template>
  <div :class="$style.root">
    <Transition name="mask">
      <NavigationMenuComponent
        v-show="showMenu"
        :isOpen="showMenu"
        @onOpenMenu="setMenu(true)"
        @onCloseMenu="setMenu(false)"
        @onOpenSearch="searchOpen = true"
      />
    </Transition>

    <Transition name="mask">
      <SearchComponent
        v-show="searchOpen"
        :isOpen="searchOpen"
        @closeSelf="searchOpen = false"
      />
    </Transition>

    <NavigationSiteHeaderComponent
      @onOpenMenu="setMenu(true)"
      @onToggleSearch="searchOpen = true"
    />

    <main :class="$style.page">
      <slot />
    </main>

    <SharedFooterComponent />
  </div>
</template>

<script setup>

const mainStore = useMainStore()

const searchOpen = ref(false)

const showMenu = computed(() => {
  return mainStore.showMenu
})

const setMenu = (bool) => {
  mainStore.setMenu(bool)
}
</script>

<style module>
.root {
  composes: font-default from global;

  display: flex;
  flex-direction: column;

  min-height: var(--100svh);
}

.page {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.grid {
  margin-top: calc(var(--unit--vertical) * 2);
}

.page>* {
  flex: 1;
}
</style>
