<template>
  <div :class="$style.root">
    <div>
      <div :class="$style.label">
        {{ $t('shared.thanksTo') }}:
      </div>
      <div :class="$style.logos">
        <a :class="$style.logo" href="https://www.mondriaanfonds.nl/" target="_blank">
          <SvgoLogoMondriaan />
        </a>
        <a :class="$style.logo" href="https://www.denhaag.nl/" target="_blank">
          <SvgoLogoTheHague />
        </a>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.root svg * {
  fill: var(--color--primary) !important;
  stroke: var(--color--primary) !important;
  width: 100%;
}

.root {
  display: flex;
  gap: var(--unit--default);
  align-items: center;
}

.label {
  composes: font-size-small label from global;
  width: 100%;
}

.logos {
  display: grid;
  grid-template-columns: repeat(2, var(--width--sponsor-logo));
  align-items: center;
}

.logo {
  padding: .25rem;
  display: flex;
  flex-direction: column;
}

.logo:not(:last-of-type) {
  border-right: var(--stroke--default) solid;
}
</style>
