<template>
  <div :class="$style.root">
    <i18n-link
      :to="{ name: 'index' }"
      :class="$style.link"
    >
      {{ $t('site.header.catchphrase') }}
    </i18n-link>
    <button
      @click="$emit('onCloseMenu')"
      :class="$style.button"
    >
      ×
    </button>
  </div>
</template>

<style module>
.root {
  position: relative;
}

.link {
  composes: reset-link from global;
}

.link:hover,
.link:global(.router-link-exact-active) {
  text-decoration-line: underline;
}

.button {
  composes: reset-button from global;

  width: calc(var(--font-small--line-height) * 1);
  height: calc(var(--font-small--line-height) * 1);

  position: absolute;
  top: 0;
  right: 0;

  transform: scale(1.15);
}
</style>
