<template>
  <i18nLink
    v-if="routeObject && linkItem?.title"
    :to="routeObject"
    :data-current-route="active ? '' : null"
    :class="$style.root"
  >
    {{ linkItem?.title }}
  </i18nLink>
  <a
    v-else-if="item?.attributes?.email?.length"
    :href="`mailto:${item?.attributes?.email}`"
    :class="$style.root"
  >
    {{ item?.attributes?.email }}
  </a>
  <a
    v-else-if="item?.attributes?.url?.length"
    :href="item?.attributes?.url"
    target="_blank"
    :class="$style.root"
  >
    {{ item?.attributes?.title }}
  </a>
  <span v-else="item?.attributes?.title" :class="$style.label">
    {{ currentLocale === 'en' ? item?.attributes?.title_en || item?.attributes?.title : item?.attributes?.title  }}
  </span>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const route = useRoute()
const { locale: currentLocale } = useI18n()

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = computed(() => getLinkItem(props.item))

const name = strapiGetRouteReference(linkItem?.value?.uid)?.name
const routeName = computed(() => nuxtApp.$getRouteBaseName())
const slug = computed(() => route?.params?.slug)

const routeObject = computed(() => {
  return baseRouteGetObject(
    strapiGetRouteReference(linkItem?.value?.uid)?.name,
    linkItem?.value?.slug
  )
})

const active = computed(() => {
  const routesWithChildren = ['education', 'exhibitions', 'events', 'projects', 'journals']
  const routeWithChildren = routesWithChildren?.some(i => i?.startsWith(name))
  if (routeWithChildren) {
    const match = routesWithChildren?.find(i => i?.startsWith(name))
    return routeName.value?.startsWith(match)
  } else {
    if (linkItem?.value?.slug) {
      if (linkItem?.value?.slug === slug.value && name === routeName.value) {
        return true
      } else {
        return false
      }
    } else {
      return name === routeName.value
    }
  }
})
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root:hover {
  text-decoration-line: underline;
}

.root[data-current-route],
.root:global(.router-link-active) {
  text-decoration-line: underline;
}

.root:not([href]) {
  color: var(--color--secondary);
  pointer-events: none;
}

.label {
  composes: font-size-small font-helvetica label-inverted from global;
}
</style>
