import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    indexPageOverlay: true,
    showMenu: false,
    showLogo: true
  }),
  actions: {
    setUserActionHasClosedIndexPageOverlay () {
      this.indexPageOverlay = false
    },
    toggleLogo() {
      this.showLogo = false
      setTimeout(() => {
        this.showLogo = true
      }, 250)
    },
    setMenu(bool: boolean) {
      this.showMenu = bool
    }
  }
})
