<template>
  <ul :class="$style.root">
    <li v-for="locale in locales" :key="locale.code">
      <NuxtLink
      :key="locale.code"
      :to="switchLocalePath(locale.code)"
      :class="$style.link"
      :data-current=" currentLocale === locale.code ? '' : null "
      >
        {{ locale.code }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup>
const { locale: currentLocale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
</script>

<style module>
.root {
  composes: reset-list from global;

  display: flex;
  /* flex-wrap: wrap; */
  gap: .5ch;
}

.link {
  composes: reset-link from global;

  display: inline-block;
}

.link[data-current] {
  text-decoration-line: underline;
}

.link:hover {
  text-decoration-line: underline;
}

.link:not([href]) {
  opacity: var(--opacity--disabled);
  pointer-events: none;
}
</style>
