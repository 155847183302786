import qs from 'qs'

export const queryMenusMenu = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      filters: {
        slug: {
          $eq: 'menu'
        }
      },
      nested: true,
      populate: {
        items: {
          populate: {
            education_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            events_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            exhibition_entry: {
              fields: ['title', 'slug', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'slug', 'locale'],
                }
              }
            },
            exhibitions_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            home_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            journals_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            page_entry: {
              fields: ['title', 'slug', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'slug', 'locale'],
                }
              }
            },
            press_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            },
            projects_page: {
              fields: ['title', 'locale'],
              populate: {
                localizations: {
                  fields: ['title', 'locale'],
                }
              }
            }
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `menus?${query}`
}
